
import { Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElMessage } from "element-plus";

interface Result {
  message: string;
  data: any;
}

export default class CreateAccountModal extends Vue {
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  dataForm: any = {
    email: "",
    status: true,
    skip_2fa: false,
    password: "",
    passwordConfirm: "",
    account: "none",
    listAccount: [],
    isNewUser: true,
    userID: "",
  };
  itemAccount: any = {
    nameAccount: "",
    note: "",
    isFreeAccount: false,
  };
  listAccountEmail: any;
  listAccountUserExist: any = [];

  closeModal() {
    this.$emit("finishHandle");
  }
  async handleAddAccount() {
    const refFrorm = this?.$refs["refFormAccount"] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;
    this.dataForm.listAccount.push(this.itemAccount);
    let item = {
      name: this.itemAccount.nameAccount,
      isFreeAccount: this.itemAccount.isFreeAccount,
      note: this.itemAccount.note,
      remove: true,
    };
    this.listAccountUserExist.push(item);
    this.itemAccount = {
      nameAccount: "",
      note: "",
      isFreeAccount: false,
    };
  }
  async getAll() {
    if (this.listAccountEmail) return;
    await this.$store.dispatch("setLoading", true, { root: true });
    let res = await axios.get("admin/user/get-all", {
      headers: this.headers,
    });
    if (res) {
      this.listAccountEmail = res.data;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }

  async getUserExist(id: any) {
    await this.$store.dispatch("setLoading", true, { root: true });
    let res = await axios.get(`admin/user?id=${id}`, {
      headers: this.headers,
    });
    if (res) {
      this.listAccountUserExist = res.data[0].account;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }

  clickDelete(item: any) {
    this.dataForm.listAccount.splice(
      this.dataForm.listAccount.indexOf(item),
      1
    );
  }
  clickDelete2(item: any) {
    this.listAccountUserExist.splice(
      this.listAccountUserExist.indexOf(item),
      1
    );
    this.dataForm.listAccount = this.dataForm.listAccount.filter(function (
      el: any
    ) {
      return el.nameAccount != item.name;
    });
  }
  async handleSave() {
    const refFrorm = this?.$refs["refForm"] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    let regexPassword =
      /(?=^.{8,20}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;

    if (!isValidate) return;
    let res: Result;
    if (regexPassword.exec(this.dataForm.password) == null) {
      return ElMessage.error(
        "Password 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be between 8-20 characters !!!"
      );
    } else if (
      this.dataForm.password !== this.dataForm.passwordConfirm &&
      this.dataForm.isNewUser
    )
      return this.$message.warning("Please match 2 password fields!", "Error");
    await this.$store.dispatch("setLoading", true, { root: true });

    res = await axios.post("admin/user", this.dataForm, {
      headers: this.headers,
    });

    if (res) {
      this.$message.success(res.message);
      this.$emit("finishHandle");
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
}
